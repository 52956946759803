import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: 1,
    galleryFirst: 0,
  };

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    updateGalleryMode: (state, action) => {
      state.mode = action.payload;
    },
    updateGalleryFirst: (state, action) => {
        state.galleryFirst = action.payload;
    },
  },
});

export default gallerySlice.reducer;
export const { updateGalleryMode, updateGalleryFirst } = gallerySlice.actions;
