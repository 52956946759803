import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeMenu: true,
  inforBar: true,
};

const sharedLayoutSlice = createSlice({
  name: "sharedLayout",
  initialState,
  reducers: {
    switchInforBar: (state) => {
      state.inforBar = !state.inforBar;
      state.activeMenu = !state.activeMenu;
    },
  },
});

export default sharedLayoutSlice.reducer;
export const { switchInforBar } = sharedLayoutSlice.actions;
