import React, { Suspense } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

const Loading = React.lazy(() => import("./pages/Loading/Loading"));
const Error = React.lazy(() => import("./pages/Error/Error"));
const SharedLayout = React.lazy(() =>
  import("./pages/SharedLayout/SharedLayout")
);
const Tour = React.lazy(() => import("./pages/Tour/Tour"));
const Plan = React.lazy(() => import("./pages/Plan/Plan"));
const Utils = React.lazy(() => import("./pages/Utilities/Utilities"));

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Routes>
              <Route path="/" index element={<Loading />} />
              <Route element={<SharedLayout />}>
                <Route index path="/tour" element={<Tour />} />
                <Route index path="/plan" element={<Plan />} />
                <Route index path="/utils" element={<Utils />} />
              </Route>
              <Route path="*" element={<Error />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
