import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  utilsActiveNumber: 0,
  utilsActiveSubmenuNumber: 0,
  utilsActiveNumberList: [
    {
      id: 1,
      text: "Thị Trấn Thuỷ Nguyên",
      scene: 'scene_0275_ttndtn',
    },
    {
      id: 2,
      text: "9C Đỗ Mười",
      scene: 'scene_0277_d9c',
    },
    {
      id: 3,
      text: "Trung Tâm Hành Chính",
      scene: 'scene_0279_tthc',
    },
    {
      id: 4,
      text: "Cầu Hoàng Văn Thụ",
      scene: 'scene_0275_chvt',
    },
    {
      id: 5,
      text: "Nhà Hát Lớn",
      scene: 'scene_0295_nhl',
    },
    {
      id: 6,
      text: "Vincom Plaza Imperia",
      scene: 'scene_0291_vcpihp',
    },
    {
      id: 7,
      text: "BV Đa Khoa Quốc Tế",
      scene: 'scene_0278_bvdkqt',
    },
  ]
};

const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    setUtilsActiveNumber: (state, action) => {
      state.utilsActiveNumber = action.payload;
    },
    setUtilsActiveSubmenuNumber: (state, action) => {
      state.utilsActiveSubmenuNumber = action.payload;
    },
  },
});

export default utilitiesSlice.reducer;
export const {
  setUtilsActiveNumber,
  setUtilsActiveSubmenuNumber,
} = utilitiesSlice.actions;
