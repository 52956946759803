import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guide: true,
  timesliderGuide: true,
};

const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    hide: (state) => {
      state.guide = false;
    },
    show: (state) => {
      state.guide = true;
    },
    hideTimeSlider: (state) => {
      state.timesliderGuide = false;
    },
    showTimeSlider: (state) => {
      state.timesliderGuide = true;
    },
  },
});

export default guideSlice.reducer;
export const { hide, show, hideTimeSlider, showTimeSlider } = guideSlice.actions;
