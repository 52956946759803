import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeNumber: 1,
  activeSubmenuNumber: 0,
  tourActiveNumberList: [
    {
      id: 1,
      text: "Toàn cảnh",
    },
    {
      id: 2,
      text: "Diamond",
    },
  ],
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setActiveNumber: (state, action) => {
      state.activeNumber = action.payload;
    },
    setActiveSubmenuNumber: (state, action) => {
      state.activeSubmenuNumber = action.payload;
    },
    setActiveSubdivisionNumber: (state, action) => {
      state.activeSubdivisionNumber = action.payload;
    },
    setTourTitle: (state, action) => {
      state.tourTitle = action.payload;
    },
    setActiveHouseSampleView: (state, action) => {
      state.activeHouseSampleView = action.payload;
    },
    setHouseSampleViewTitle: (state, action) => {
      state.houseSampleViewTitle = action.payload;
    },
    setPlanViewSelectedList: (state, action) => {
      state.planViewSelectedList = action.payload;
    },
    setActivePlanView: (state, action) => {
      state.activePlanView = action.payload;
    },
  },
});

export default tourSlice.reducer;
export const {
  setActiveNumber,
  setActiveSubmenuNumber,
  setActiveSubdivisionNumber,
  setTourTitle,
  setActiveHouseSampleView,
  setHouseSampleViewTitle,
  setPlanViewSelectedList,
  setActivePlanView,
} = tourSlice.actions;
